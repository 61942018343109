import { DocumentQualityRadio } from 'components/admin/DocumentQualityRadio'
import { ProviderLink } from 'components/admin/ProviderLink'
import { Badge } from 'components/Badge'
import { Box } from 'components/Box'
import { Button } from 'components/Button/Button'
import { IconButton } from 'components/Buttons/IconButton'
import { SplitButton } from 'components/Buttons/SplitButton'
import { DocumentOwnerButton, getLabel } from 'components/Documents/DocumentOwnerButton'
import { DocumentStatusFilter } from 'components/Documents/DocumentStatusFilter'
import { DocumentTypeMarketRequirements } from 'components/Documents/DocumentTypeMarketRequirements'
import FormGroupSection from 'components/FormGroupSection/FormGroupSection'
import { Input } from 'components/Input'
import { ExpirationLabel } from 'components/Labels/ExpirationLabel'
import { Loader } from 'components/Loader'
import { ChecksModal } from 'components/Modals/ChecksModal'
import { DocumentHistoryModal } from 'components/Modals/DocumentHistoryModal'
import { Text } from 'components/Text'
import { SECTIONS, STATUS_OPTIONS, StatusOption } from 'constants/document'
import { formatDate } from 'internal/util/dates'
import { admin } from 'lib/api'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { HiOutlineLink, HiX } from 'react-icons/hi'
import { Document } from 'types/Document'
import { RejectionReason } from 'types/RejectionReason'
import { DocumentRejectModal } from './DocumentRejectModal'
import { Field } from './Field'

type DocumentFieldsProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  document: Document
  detected: any
  requiredFields: any
  onRefresh: () => Promise<void>
}

export const DocumentFields = (props: DocumentFieldsProps) => {
  const { document: doc, detected, requiredFields, onRefresh, ...rest } = props
  const [statusOption, setStatusOption] = useState<StatusOption>()
  const [quality, setQuality] = useState<number | null>(null)
  const [documentRejectModalOpen, setDocumentRejectModalOpen] = useState(false)
  const [additionalNotes, setAdditionalNotes] = useState<RejectionReason>({
    category: 'Other',
    description: '',
    canOverride: true,
    shouldOverwrite: false,
  })
  const [rejectionReasons, setRejectionReasons] = useState<RejectionReason[]>([])
  const [showHistory, setShowHistory] = useState(false)
  const [showChecks, setShowChecks] = useState(false)
  const [addingTag, setAddingTag] = useState(false)
  const tagBtnRef = useRef<HTMLButtonElement>(null)
  const tagInputRef = useRef<HTMLInputElement>(null)
  const groupName = (doc.owner?.driver || doc.owner?.vehicle || doc.owner?.provider)?.documentGroup?.name

  const setDefaultValues = useCallback(() => {
    if (!doc) return
    setStatusOption(STATUS_OPTIONS.find(o => o.value === doc.status))

    const reasons = doc.statusReason
      ?.split('\n\n')
      ?.filter(Boolean)
      .map(r => {
        const colonIndex = r?.indexOf(':')

        let category = ''
        let description = ''

        if (colonIndex !== -1) {
          category = r.slice(0, colonIndex)
          description = r.slice(colonIndex + 1)
        } else {
          category = 'Rejected'
          description = r
        }

        return {
          category,
          description: description.trim(), // Trimming to remove any leading spaces
          canOverride: doc?.decision?.canOverride,
          shouldOverwrite: false,
        }
      })

    if (reasons?.length > 0) {
      setRejectionReasons(reasons.filter(r => r.category !== 'Other'))
      setAdditionalNotes(
        reasons.find(r => r.category === 'Other') || {
          category: 'Other',
          description: '',
          canOverride: true,
          shouldOverwrite: false,
        },
      )
    }

    if (doc.extractedData && doc.extractedData.quality !== null) {
      setQuality(doc.extractedData.quality)
    }
  }, [doc])

  const handleAddTag = useCallback(
    async e => {
      e.preventDefault()
      e.stopPropagation()

      if (addingTag) {
        return
      }

      if (doc.tags.find(t => t.tag === tagInputRef?.current.value)) {
        return
      }

      setAddingTag(true)

      await admin.addDocumentTag({ documentId: doc.id, tag: tagInputRef.current?.value })
      tagInputRef.current.value = ''
      await onRefresh()

      setAddingTag(false)
    },
    [doc, setAddingTag, addingTag, onRefresh],
  )

  const handleRemoveTag = useCallback(
    async (id: string) => {
      await admin.removeDocumentTag({ id })
      await onRefresh()
    },
    [onRefresh],
  )

  useEffect(() => {
    setDefaultValues()
  }, [doc, setDefaultValues])

  useEffect(() => {
    if (showChecks) {
      onRefresh()
    }
  }, [showChecks, onRefresh])

  const handleRejectionReasons = (reasons: RejectionReason[]) => {
    const additionalReason = reasons.find(r => r.category === 'Other')
    const rejectionReasons = reasons.filter(r => r.category !== 'Other')
    setRejectionReasons(rejectionReasons)
    setAdditionalNotes(
      additionalReason?.description
        ? additionalReason
        : {
            category: 'Other',
            description: '',
            canOverride: true,
            shouldOverwrite: false,
          },
    )

    setDocumentRejectModalOpen(false)
  }

  const filteredRejectionReasons = [...rejectionReasons, additionalNotes]?.filter(r => r.description)

  return (
    <div {...rest}>
      {doc && statusOption && (
        <div className="flex h-full flex-1 flex-col overflow-auto">
          <FormGroupSection label="Provider">
            <Field title="Market">
              <Text size="xs">{doc.provider.market?.name}</Text>
            </Field>
            <Field title="Name">
              <div className="text-xs">
                <ProviderLink size="xs" baseUrl="/admin" provider={doc.provider} />
              </div>
            </Field>
            <Field title="Type">
              <Text size="xs">{doc.provider.documentGroup?.name}</Text>
            </Field>
          </FormGroupSection>
          <FormGroupSection label="Owner Information">
            <Field title="Owner">
              {doc.owner ? (
                <div className="flex flex-1 items-center justify-between gap-2">
                  <Text size="xs">{getLabel(doc?.owner)}</Text>
                  <div>
                    <DocumentOwnerButton baseUrl="/admin" document={doc} showLabel={false} size="xs" />
                  </div>
                </div>
              ) : (
                <div className="inline-flex flex-1 items-center justify-between">
                  <SplitButton className="flex-1">
                    <Button className="flex-1" disabled>
                      None
                    </Button>
                    <IconButton ariaLabel="Link document to owner" type="button" icon={HiOutlineLink}>
                      Link
                    </IconButton>
                  </SplitButton>
                </div>
              )}
            </Field>
            <Field title="Group">
              <Text size="xs">{groupName}</Text>
            </Field>
          </FormGroupSection>
          <FormGroupSection label="Document Information">
            <Field title="ID">
              <Text size="xs">{doc.id}</Text>
            </Field>

            <Field title="Type">
              <div className="flex items-center justify-between gap-2">
                <Text size="xs">{doc.type?.name}</Text>
                <DocumentTypeMarketRequirements documentType={doc.documentType} market={doc.provider?.market?.name} />
              </div>
            </Field>
            <Field title="Status">
              <input type="hidden" value={statusOption.value} name="status" />
              <input
                type="hidden"
                value={rejectionReasons.some(r => r.canOverride) ? '1' : '0'}
                name="rejectionReasonCanOverride"
              />

              <DocumentStatusFilter
                className="w-full flex-1"
                label={null}
                variant="split"
                value={statusOption}
                onChange={option => {
                  setStatusOption(option)
                  if (option.value === 'rejected') {
                    setDocumentRejectModalOpen(true)
                  }
                }}
                disabled={['approved', 'rejected', 'archived'].includes(doc.status) || doc.isExtracting}
              />
            </Field>
            {(doc.status === 'rejected' || statusOption.value === 'rejected') && (
              <Field title="Reason">
                <Box className="flex w-full flex-col gap-2">
                  {filteredRejectionReasons.length > 0 ? (
                    <Fragment>
                      <textarea
                        hidden
                        aria-hidden
                        disabled
                        rows={4}
                        id="statusReason"
                        name="statusReason"
                        className="sm:text-s dark:bg-dark-900 flex-1 rounded-md"
                        value={filteredRejectionReasons.map(r => `${r.category}:${r.description}`).join('\n\n')}
                      />
                      <Box className="flex flex-col gap-2">
                        {filteredRejectionReasons.map((r, i) => (
                          <Text as="span" size="sm" weight="normal" key={i}>
                            <Text as="span" size="sm" weight="semibold">
                              {r.category}:
                            </Text>{' '}
                            {r.description}
                          </Text>
                        ))}
                      </Box>
                    </Fragment>
                  ) : null}
                  <Button
                    block
                    size="sm"
                    onClick={() => setDocumentRejectModalOpen(true)}
                    disabled={doc.status === 'rejected'}
                  >
                    {rejectionReasons?.length > 0 ? 'Update' : 'Choose'}
                  </Button>
                </Box>
              </Field>
            )}

            <Field title="Document Quality" required={true}>
              {quality !== null && <input type="hidden" value={quality} name="quality" />}
              <DocumentQualityRadio value={quality || 0} onChange={setQuality} />
            </Field>

            <Field title="Warnings / Issues">
              <ExpirationLabel date={doc.expiresAt} full />
            </Field>
          </FormGroupSection>
          {!requiredFields ? (
            <Loader />
          ) : (
            SECTIONS.map(section => {
              const fields = section.fields
                .map(field => {
                  if (
                    !doc.documentType ||
                    !requiredFields[doc.documentType] ||
                    !requiredFields[doc.documentType][field.name]
                  ) {
                    return null
                  }

                  let defaultValue = doc.extractedData?.[field.name] || detected?.[field.name] || ''
                  if (field.type === 'date') {
                    defaultValue = formatDate(defaultValue, 'MM/dd/yyyy')
                  }

                  return (
                    <Field
                      key={`${section.name}.${field.name}`}
                      id={field.name}
                      title={field.title}
                      subtitle={field.subtitle}
                      type={field.type}
                      document={doc}
                      defaultValue={defaultValue}
                      className="dark:bg-dark-800"
                      width={section.selectable === false ? 'w-2/3' : 'w-1/3'}
                      required={
                        statusOption?.value !== 'rejected' && requiredFields[doc.documentType][field.name] === '2'
                      }
                    />
                  )
                })
                .filter(Boolean)

              if (!fields.length) {
                return null
              }

              return (
                <FormGroupSection key={section.name} label={`${section.name} Fields`} expanded>
                  {fields}
                </FormGroupSection>
              )
            })
          )}
          <FormGroupSection label="Metadata">
            <Field title="Tags">
              <div className="flex flex-1 flex-col gap-2">
                {doc.tags?.length > 0 && (
                  <div className="flex flex-wrap gap-2">
                    {doc.tags.map(t => (
                      <Badge key={t.id} size="sm" className="group mb-1" color="white">
                        {t.tag}
                        <IconButton
                          className="hidden group-hover:block"
                          variant="fill"
                          size="xs"
                          icon={HiX}
                          ariaLabel="Remove tag"
                          onClick={() => {
                            handleRemoveTag(t.id)
                          }}
                        />
                      </Badge>
                    ))}
                  </div>
                )}
                <div className="inline-flex items-center">
                  <Input
                    ref={tagInputRef}
                    placeholder="Add a tag"
                    className="dark:border-dark-500 dark:bg-dark-900 rounded-r-none"
                    disabled={addingTag}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        tagBtnRef.current?.click()
                      }
                      e.stopPropagation()
                    }}
                  />
                  <Button
                    loading={addingTag}
                    ref={tagBtnRef}
                    type="button"
                    className="-ml-px rounded-l-none"
                    onClick={handleAddTag}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </Field>
          </FormGroupSection>
        </div>
      )}

      <DocumentRejectModal
        documentType={doc.documentType}
        isOpen={documentRejectModalOpen}
        onSubmit={handleRejectionReasons}
        onClose={() => setDocumentRejectModalOpen(false)}
      />
      <DocumentHistoryModal isOpen={showHistory} onClose={() => setShowHistory(false)} document={doc} />
      <ChecksModal isOpen={showChecks} onClose={() => setShowChecks(false)} checks={doc?.checks || []} />
    </div>
  )
}
