import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useQuery } from '@apollo/client'
import { Icon } from 'components/Icon'
import { QUERY_MARKET_REQUIREMENTS } from 'internal/graphql/query/market'
import { useMemo } from 'react'
import { HiQuestionMarkCircle } from 'react-icons/hi2'
import Markdown from 'react-markdown'

export function DocumentTypeMarketRequirements({ documentType, market }: { documentType?: String; market?: String }) {
  const { data } = useQuery(QUERY_MARKET_REQUIREMENTS, {
    variables: {
      documentType,
      market,
    },
  })

  const descriptions = useMemo(() => {
    let descs = data?.descriptions ?? []
    if (!descs.length) {
      descs = ['No market requirements found.']
    }
    return descs
  }, [data])

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger type="button">
          <Icon icon={HiQuestionMarkCircle} size="sm" />
        </TooltipTrigger>
        <TooltipContent className="markdown max-w-lg overflow-auto">
          {descriptions.map((d, i) => {
            return <Markdown key={i}>{d.replaceAll('\n', '\n\n')}</Markdown>
          })}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
