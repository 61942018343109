import { graphqlFetcher } from 'internal/graphql/fetcher'
import { QueryDrivers } from 'internal/graphql/query/driver'
import useSWR from 'swr'
import { Driver } from 'types/Driver'
import { QueryResponse } from 'types/Query'

type UseDriversOptions = {
  page: number
  query?: string
  limit?: number
  sort?: string
  ids?: string[] | null
  marketId?: string
  documentGroupId?: string
  providerIds?: string[]
  active?: boolean
  activeBrokerLink?: boolean
  skip?: boolean
}

export function useDrivers(options: UseDriversOptions = { page: 1 }) {
  const { skip, ...opts } = options
  const key = !skip ? [QueryDrivers, opts] : null
  const { data, isValidating, mutate } = useSWR<QueryResponse<'drivers', Driver>>(key, graphqlFetcher)

  const drivers = data?.drivers?.results || []
  const total = data?.drivers?.total || 0

  return {
    drivers,
    total,
    loading: isValidating,
    refresh: () => mutate(),
  }
}
